
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

interface Request {
  method: string;
  endpoint: string;
  body?: Record<string, any>;
}

export const fetchData = async (request: Request): Promise<any> => {
  const { method, endpoint, body } = request;
  const sessiontoken = localStorage.getItem('sessiontoken');
  const requestModel: AxiosRequestConfig = {
    method,
    url: endpoint,
    data: body
  };
  if (sessiontoken)
    requestModel.headers = {
      sessiontoken: sessiontoken
    };
  try {
    const response: AxiosResponse<any> = await axios.request(requestModel);
    return response.data;
  } catch (e:any) {
    return {
      status: e.response?.status,
      data: e.response?.data
    }
  }
};

export const fetchDataWithoutSession = async (request: Request): Promise<any> => {
  const { method, endpoint, body } = request;
  
  const requestModel: AxiosRequestConfig = {
    method,
    url: endpoint,
    data: body
  };  
  
  try {
    const response: AxiosResponse<any> = await axios.request(requestModel);
    return response.data;
  } catch (e:any) {
    return {
      status: e.response?.status,
      data: e.response?.data
    }
  }
};
