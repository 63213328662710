const RESTAPI = 'https://evai.in/'


export const restAPIs = {

  login: (body: any) => ({
    method: 'post',
    body,
    endpoint: `https://ppanel.electronicvillage.org/json_login.php`
  }),

  bookUpdate: (body: any) => ({
    method: 'post',
    body,
    endpoint: `https://ppanel.electronicvillage.org/json_book_update.php`
  }),

  bookList: (page:any, interval:any, isAiEnabled:any, searchText:any) => ({
    method: 'get',
    endpoint: `https://alwaraq.net/json_booklist.php?language=2&page=${page}&interval=${interval}${isAiEnabled != '2' ? '&isAiEnabled='+isAiEnabled : ''}&searchText=${searchText}`
  }),
  

  createModal: (body: any) => ({
    method: 'post',
    body,
    endpoint: `${RESTAPI}chromadb/upload`
  }),  

  bookPages: (bookId:any) => ({
    method: 'get',
    endpoint: `https://alwaraq.net/json_bookallpages.php?language=2&bookId=${bookId}`
  }),

  createAnswer: (query: any, documentId: any) => ({
    method: 'get',
    endpoint: `${RESTAPI}chromadb/answer?query=${query}&document_id=${documentId}`
  }),

  createQuestion: (body: any) => ({
    method: 'post',
    body,
    endpoint: `https://alwaraq.net/quiz_createQuestions.php`
  }),
  createQuestionTen: (body: any) => ({
    method: 'post',
    body,
    endpoint: `https://alwaraq.net/quiz_create10Questions.php`
  }),

};
