// Assuming USER_DETAILS is a string constant in 'config/actionTypes'
import { ActionTypes } from 'utils/enums';
import initialState from './initialState';

// Reducer function
const commonData = (
  state: any = initialState,
  action: any
) => {
  switch (action.type) {
    case ActionTypes.USER_DETAILS:
      return {
        ...state,
        userDetails: action.value,
      };
      
    default:
      return state;
  }
};

export default commonData;
