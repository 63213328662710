// Core
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    UserOutlined,

} from "@ant-design/icons";
// Assets

// Styles
import { HeaderOuter } from './styles';
import { Avatar, Drawer, Dropdown, Flex, Popconfirm } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';



const Header: React.FC = () => {
    const { userDetails } = useSelector((store: any) => store.commonData);
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const noHeader = ["/login"].includes(location.pathname);

    useEffect(() => {
        const localDetails = localStorage.getItem("userDetails");
        if (!userDetails && localDetails) {
            dispatch({
                type: "userDetails",
                value: JSON.parse(localDetails),
            });
        }

    }, [])

    const cancel = (e: any) => {
        console.log(e);
    }

    const handleLogout = async () => {
        dispatch({
            type: "userDetails",
            value: "",
        });
        localStorage.setItem("sessiontoken", '');
        localStorage.setItem("userDetails", "");
        navigate('/login')
    };

    if (noHeader) return null;
    return (
        <HeaderOuter >
            <div className='__hd_left'>
                <h1>Hello {userDetails && userDetails.email},</h1>
                <p>Create and track the Alwaraq Books AI models.</p>
            </div>
            <div className='__hd_right'>
                <Popconfirm
                    title="Log Out"
                    description="Are you sure want to Logout?"
                    onConfirm={handleLogout}
                    onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                    placement="bottomRight"
                >
                    <Avatar style={{ backgroundColor: '#87d068', cursor: 'pointer' }} icon={<UserOutlined />} />
                </Popconfirm>
            </div>
        </HeaderOuter>
    );
};

export default Header;
