import { colors } from "assets/styles/colors";
import { FontSizes } from "utils/enums";
import styled from "styled-components";


export const HeaderOuter = styled.div`
      padding:30px;
      display:flex;
      justify-content:space-between;
      align-items:center;
      max-width:80vw;
      margin:0 auto;
      margin-bottom:10px;
      background:#fff;
      border-radius:10px;
      box-shadow:0px 0px 10px #ccc3;
      h1 {
            margin:0;
            font-size:18px;
            font-weight:600;
            margin-bottom:5px;
      }
      p {
            margin:0;
            color:#b5b5b5;
            font-size:17px;
            font-weight:500;
      }

  `;