import { colors } from 'assets/styles/colors';
import { Label } from 'assets/styles/globalStyles';
import { FontSizes } from 'utils/enums';
import styled, { css, keyframes } from "styled-components";

interface StyledComponentProps {
  marginTop?: number;
  marginBottom?: number;
  error?: boolean;
}

export const ErrorLabel = styled(Label)`
  margin-top: 4px;
  text-align:left;
`

export const EyeBox = styled.div`
  right: 12px;
  top: 17px;
  font-size: 1.1rem;
  position: absolute;
  cursor: pointer;
  color: ${colors.primary}
`

const slideDownAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;


export const Container = styled.div<StyledComponentProps>`
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : 0)};
  margin-bottom: ${({ marginBottom }) => (marginBottom ? `${marginBottom}px` : 0)};
  position: relative;
  width:100%;

  .ant-input {
    height: 55px;
    border-width: 1.5px;
    border-radius: 6px;
    border-color:  ${({ error }) => (error ? colors.error : '#ddd')};
    font-size: ${FontSizes.input}rem;
    background-color: white;
    width:100%;
  }

  ${ErrorLabel} {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
    ${({ error }) =>
    error &&
    css`
        opacity: 1;
        transform: translateY(0);
        animation: ${slideDownAnimation} 0.5s ease;
      `}
  }

  input::placeholder {
    font-size: ${FontSizes.default}rem;
    color: #ccc;
  }
`;