import { colors } from 'assets/styles/colors';
import { FontSizes } from 'utils/enums';
import styled from "styled-components";

export const Wrapper = styled.div`
  label{
        font-size: 1rem;
    color: #222;
    font-weight: 600;
      }
    .ant-select {
      height: 55px;
      width: 100% !important;

    
      .ant-select-selection-item {
        font-size: 1rem;
        color: ${colors.default};
      }
      
      .ant-select-selector {
        border: 1.5px solid #ccc;
        border-radius: 6px;
      }

      &:hover {
        .ant-select-selector {
        border: 1.5px solid #ccc !important;
      }
      }
    }

    .ant-select-selection-placeholder {
      font-size: ${FontSizes.default}rem !important;
      color: #ccc !important;
    }

`;