// Core
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createStore, applyMiddleware, Store } from 'redux';
import { Provider } from 'react-redux';
import { thunk } from 'redux-thunk';
import { createLogger } from 'redux-logger';
import RootReducer from './reducers/rootReducer';
import App from './App';
import { ConfigProvider } from 'antd';
import { colors } from 'assets/styles/colors';
const logger = createLogger();

// Create the Redux store with initial state and middleware
const store: Store<any> = createStore(RootReducer, applyMiddleware(thunk));

// Get the root container
const container = document.getElementById('root')!; // Non-null assertion

// Create a root with the container
const root = createRoot(container);

// Wrap your App component with the Redux Provider
const reduxApp = (
  <ConfigProvider theme={{ token: { colorPrimary: colors.primary } }}>
    <Provider store={store}>
      <App />
    </Provider>
  </ConfigProvider>
);

// Render the application
root.render(reduxApp);