import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Login from 'pages/Login';
import ProtectedRoute from './ProtectedRoute';
import { isAuthenticated } from 'utils/helpers';
import Homepage from 'pages/Homepage';
import { useSelector } from 'react-redux';


const AppRouter = () => {
  const { userDetails } = useSelector((store:any) => store.commonData);
  console.log("window.location.href", window.location.href);
  
  const isLogin = window.location.href.includes('login');

  return (
    <>
      <BrowserRouter>
        <div className='__dark' style={{ padding: isLogin ? '0px' : '20px' }}>
        <Header />
          <Suspense fallback={<Loader />}>
            <Routes>
              {/* Private Routes */}
              <Route element={<ProtectedRoute />}>
                <Route path="/home" element={<Homepage />} />
                <Route path="*"  element={<Homepage />} />
              </Route>

              {/* Public Routes */}
              <Route
                path="/login"
                element={isAuthenticated() ? <Navigate to="/home" /> : <Login />}
              />
            </Routes>
          </Suspense>
        </div>
      </BrowserRouter>
    </>
  );
};

export default AppRouter;
