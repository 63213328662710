import styled, { createGlobalStyle, css } from "styled-components";

// Types
import { GridProps, LabelProps } from "./types";
import { FontSizes, FontWeight } from "utils/enums";
import { colors } from "./colors";


export const Label = styled.label<LabelProps>`
  display: block;
  padding-top: ${({ paddingTop }) => (paddingTop ? `${paddingTop}px` : "0")};
  padding-right: ${({ paddingRight }) =>
    paddingRight ? `${paddingRight}px` : "0"};
  padding-bottom: ${({ paddingBottom }) =>
    paddingBottom ? `${paddingBottom}px` : "0"};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? `${paddingLeft}px` : "0"};
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}px` : "0")};
  margin-right: ${({ marginRight }) =>
    marginRight ? `${marginRight}px` : "0"};
  margin-bottom: ${({ marginBottom }) =>
    marginBottom ? `${marginBottom}px` : "0"};
  margin-left: ${({ marginLeft }) => (marginLeft ? `${marginLeft}px` : "0")};
  font-size: ${({ fontSize }) =>
    `${fontSize ? fontSize : FontSizes.default}rem`};
  color: ${({ color }) => color || "inherit"};
  font-weight: ${({ fontWeight }) => fontWeight || "normal"};

  ${({ pointer }) => css`
    ${pointer &&
    css`
        cursor: pointer;
      `}
  `}

  span {
    color: ${colors.error};
  }
`;
