// Core
import React from 'react';
// Others
import AppRouter from 'routes';

// Styles
import './App.css'


export function App() {


  return (
      <>
      <AppRouter />
      </>
  );
}

export default App;
