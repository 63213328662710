import { colors } from "assets/styles/colors";
import { FontSizes } from "utils/enums";
import styled from "styled-components";

export const LoginWrapper = styled.div`
  width: 100%;
  position: relative;

  .__page_wrapper {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background: #cccccc22;
  }

  .__inner_wrapper {
    text-align: center;
    width: 28vw;
    padding: 50px 40px;
    border-radius: 12px;
    background: #fff;

    @media (max-width: 756px) {
      width: 80%;
      margin: 0 auto;
    }

    h1 {
      font-size: 36px;
      margin: 6px 0px;
      font-weight: 700;
      margin-bottom: 33px;
    }

    h3 {
      font-size: 22px;
      margin: 5px 0;
      font-weight: 600;
    }

    img {
      width: 100px;
    }
  }
`;
