/* eslint-disable react-hooks/exhaustive-deps */
// Core
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
    CloudUploadOutlined,
    UserOutlined,
} from "@ant-design/icons";
import { NavLink, useNavigate } from "react-router-dom";

// Styles

// Types
import {
    LoginWrapper
} from "./styles";

// Components
import Button from "components/Button";
import InputField from "components/InputField";

// Others
import { fetchData } from "utils/fetch";
import { restAPIs } from "utils/restAPIs";
import { useDispatch, useSelector } from "react-redux";
import { SignInParameters } from "./types";
import { validateEmail } from "utils/helpers";
import { message } from "antd";

import Logo from 'assets/images/logo.png'


const Login: React.FC = () => {
    const { currentLang, userDetails } = useSelector((store: any) => store.commonData);
    const [messageApi, contextHolder] = message.useMessage();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form, setForm] = useState<SignInParameters>({
        email: "",
        password: "",
    });
    const [loading, setLoading] = useState(false);
    const { email, password } = form;
    const [errorsLogin, setErrorsLogin] = useState<SignInParameters>({
        email: "",
        password: "",
    });

    const validateForm = (): boolean => {
        const newError = {
            email: validateEmail(email) ? '' : 'Please enter a valid email address',
            password: password ? '' : 'Password is required',
        }
        setErrorsLogin(newError)
        return !Object.values(newError).some(item => !!item);
    };

    const onchangeHandler = (key: string) => (value: string) => {
        setForm({
            ...form,
            [key]: value,
        });
    };


    const handleSignIn = async () => {
        if (!validateForm()) return null;
        setLoading(true);
        const formData = new FormData();
        formData.append('email', email.toLowerCase())
        formData.append('password', btoa(unescape(encodeURIComponent(password))))
        formData.append('appid', '1')
        const res = await fetchData(restAPIs.login(formData));
        if (res.statusCode === 200) {
            dispatch({
                type: 'userDetails',
                value: res.userinfo
            });
            localStorage.setItem('userDetails', JSON.stringify(res.userinfo));
            localStorage.setItem('sessiontoken', res.userinfo.sessionToken);
            navigate('/home');
        }

        else {
            messageApi.open({
                type: 'error',
                content: res?.errormessage,
            });
        }

        setLoading(false);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
            handleSignIn();
        }
    };


    return (
        <LoginWrapper>
            {contextHolder}

            <div className="__page_wrapper">
                <div className="__inner_wrapper">
                    <img src={Logo} alt="Alwaraq" />
                    <h3>Alwaraq Books</h3>
                    <h1>Administration</h1>

                    <InputField
                        error={errorsLogin.email}
                        onChange={onchangeHandler("email")}
                        value={email}
                        marginBottom={20}
                        placeholder={'Username'}
                        color="#222"
                        onKeyDown={handleKeyDown}
                    />
                    <InputField
                        error={errorsLogin.password}
                        onChange={onchangeHandler("password")}
                        value={password}
                        placeholder={'Password'}
                        type="password"
                        color="#222"
                        onKeyDown={handleKeyDown}
                    />
                    <Button
                        loading={loading}
                        onClickHandler={handleSignIn}
                        marginTop={12}
                        width="100%"
                        label={'Login'}
                    />
                </div>
            </div>
        </LoginWrapper>
    );
};

export default Login;

